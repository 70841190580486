import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { v4 as uuidv4 } from 'uuid';
import AgenciaService from '../../services/AgenciaService';
import AutenticacaoService from '../../services/AutenticacaoService';
import CarrinhoService from '../../services/CarrinhoService';
import GeografiaService from '../../services/GeografiaService';
import TagueamentoService from '../../services/TagueamentoService';
import ValidaCNPJ from '../../utils/ValidaCNPJ';
import ValidaCPF from '../../utils/ValidaCPF';
import validarEmail from '../../utils/ValidaEmail';
import validarTelefone from '../../utils/validarTelefone';
import CalculadoraModal from '../CalculadoraModal/CalculadoraModal';
import Input from '../FlexFreteInput/Input';
import Modal from '../FlexFreteModal/Modal';
import FlexSpinner from '../FlexFreteSpinner/FlexSpinner';
import { BtnSection, CalculadoraComplementarForm, FormStyle } from './style';
import { moneyMask } from "../../utils/Masks";

const geografiaService = new GeografiaService();
const agenciaService = new AgenciaService();
const validacaoDo = new ValidaCPF();
const validacaoPj = new ValidaCNPJ();
const authService = new AutenticacaoService();
const carrinhoService = new CarrinhoService();
const tagueamentoService = new TagueamentoService();

function CalculadoraLogadoInfosComplementares({redirect, edit}) {

    const usuario = authService.usuarioCorrente();

    let {freteCotacaoSelecionada} = useSelector((state) => {
      return {
          ...state
      };
    });

    const maxCaracteresDeclaracao = 255;
    const maxCaracteresNotaFiscal = 54;
    const maxCaracteresComplementoEndereco = 20;
    const maxCacteresNumeroEndereco =  5;
    const maxCfop = 4;
    const VALOR_MAXIMO_DECLARACAO_CONTEUDO = Number(process.env.REACT_APP_VALOR_MAXIMO_DECLARACAO_CONTEUDO)

    const cotacao = freteCotacaoSelecionada;
    const carrinhoInformacoes = edit || {};
    const [semNumero, setSemNumero] = useState(false)
    const numero = useRef();
    const [semNumeroOrigem, setSemNumeroOrigem] = useState(false)
    const numeroOrigem = useRef();
    const [city, setCity] = useState(false)
    const [cityOrigem, setCityOrigem] = useState(false)
    const valorEncomenda = localStorage.getItem('valorEncomenda');
    const valorEncomendaNumerico = parseFloat(valorEncomenda);
    const valorComparativo = !isNaN(valorEncomendaNumerico) ? valorEncomendaNumerico : 0;
    const [cfop, setNumeroCfop] = useState(carrinhoInformacoes?.notaFiscal?.cfop || "")
    const [telefoneMask, setTelefoneMask] =  useState("(99)99999-9999");
    const [isCnpj, setIsCnpj] = useState(carrinhoInformacoes.isCnpj);
    const [sucesso, setSucesso] = useState({
      isSucesso: false,
      titulo: "",
      mensagem: "",
    });
    const loja = useSelector((state) => state.loja);
    const usarDadosUsuario = useSelector((state) => state.usarDadosUsuario);

    const [request, setRequest] = useState({
        nome: carrinhoInformacoes?.destinatario?.nome,
        sobrenome: carrinhoInformacoes?.destinatario?.sobrenome,
        cpfCnpj: carrinhoInformacoes?.destinatario?.cpfCnpj,
        telefone: carrinhoInformacoes?.destinatario?.telefone,
        email: carrinhoInformacoes?.destinatario?.email,
        logradouro: "",
        numero: carrinhoInformacoes?.destinatario?.endereco?.numero?.substring(0,maxCacteresNumeroEndereco) || "",
        numeroOrigem: carrinhoInformacoes?.remetente?.endereco.numero.substring(0,maxCacteresNumeroEndereco),
        cep: "",
        bairro: "",
        cidade: "",
        complemento: carrinhoInformacoes?.destinatario?.endereco?.complemento?.substring(0,maxCaracteresComplementoEndereco) || "",
        complementoOrigem: carrinhoInformacoes?.remetente?.endereco?.complemento?.substring(0,maxCaracteresComplementoEndereco) || "",
      });
    const itens = carrinhoInformacoes?.conteudo?.itens ||
      [{
        key: uuidv4(),
        descricao: "",
        quantidade: "1",
      }];

      useEffect(() => {
        if(loja?.uuid && loja?.ativo && loja?.endereco?.cep === cotacao.cepOrigem){
          setRequest((prevState) => ({
            ...prevState,
            numeroOrigem: loja?.endereco?.numero.substring(0,maxCacteresNumeroEndereco),
            complementoOrigem: loja?.endereco?.complemento.substring(0,maxCaracteresComplementoEndereco),
          }))
        }
      }, [cotacao.cepOrigem, loja]);



    const toogleNotaDeclaracao = () => {

      if(!showDeclaracao){
        setConteudo([{
          key: uuidv4(),
          descricao: "",
          quantidade: "1",
        }])
      }

      if(!showNotaFiscal){
        setNumeroNotaFiscal(null)
      }

    }

    const handleDeclaracaoChange = (event) => {
      if(event.target.checked){
        setShowDeclaracao(true)
        setShowNotaFiscal(false);
      }
      toogleNotaDeclaracao();
    };

    const handleNotaFiscalChange = (event) => {
      if(event.target.checked){
        setShowNotaFiscal(true);
        setShowDeclaracao(false)
      }
      toogleNotaDeclaracao();
    };


    const [conteudo, setConteudo] = useState([...itens]);
    const [preencheConteudo, setPreencheConteudo] = useState(false)
    const [numeroNotaFiscal, setNumeroNotaFiscal] = useState(carrinhoInformacoes?.notaFiscal?.numeroDanfCte || [])
    const [agenciaSelect, setAgenciaSelect] = useState(carrinhoInformacoes?.agencia ? carrinhoInformacoes.agencia : undefined)
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cidadesDestino, setCidadesDestino] = useState([]);
    const [finalizarPagamento, setfinalizarPagamento] = useState(false)
    const [showDeclaracao, setShowDeclaracao] = useState(false);
    const [showNotaFiscal, setShowNotaFiscal] = useState(false);
    const [logradouro, setLogradouro] = useState();
    const [bairro, setBairro] = useState();
    const [logradouroOrigem, setLogradouroOrigem] = useState();
    const [bairroOrigem, setBairroOrigem] = useState();
    const [disabledEndereco, setDisabledEndereco] = useState({
      enderecoBairro: true,
      estadoMunicipio: true
    });
    const [disabledEnderecoOrigem, setDisabledEnderecoOrigem] = useState({
      enderecoBairro: false,
      estadoMunicipio: false
    });
    const [cepEndereco, setCepEndereco] = useState({
      cep: "",
      bairro: "",
      nomeCidade: "",
      cidade: undefined,
      logradouro: "",
      siglaUf: undefined,
      numero: "",
      complemento: "",
    });
    const [cepEnderecoOrigem, setCepEnderecoOrigem] = useState({
      cep: "",
      bairro: "",
      nomeCidade: "",
      cidade: undefined,
      logradouro: "",
      nomeUf: "",
      siglaUf: undefined,
      numero: "",
      complemento: "",
    });

    const setCfop = (event) =>{
        event.target.setCustomValidity("")
        const inputValue = event.target.value
        const filteredValue = inputValue.replace(/\D/g, "");
        if(filteredValue.toString().length <=4){
            setNumeroCfop(filteredValue);
        }

    }


    const callbackModalSucesso = () =>{
      if(sucesso.isWrong === false){
        setSucesso({isWrong: true})
      }
    }

    const [agencias, setAgencias] = useState([])
    const [erro, setErro] = useState({
      isWrong: false,
      titulo: "",
      mensagem: "",
      voltar: false,
      elementCallback: null,
    });

    const setNotaFiscal = (event) => {
      event.target.setCustomValidity("")
      const inputValue = event.target.value
      if(inputValue.replace(/\D/g, "").length <= 44){
        const filteredValue = inputValue.replace(/[^\d\s]/g, "");
        const finalValue = filteredValue.replace(/(\s)\s/g, "$1");
        setNumeroNotaFiscal(finalValue)
      }
    }

    const [adicionado, setAdicionado] = useState({
        isAdd: false,
        titulo: "",
        mensagem: "",
        voltar: false,
      });

    const [processando, setProcessando] = useState(false);
    const [enviandoInformacoes, setEnviandoInformacoes] = useState(false);
    const [agenciaOk, setagenciaOk] = useState(false);
    const [dadosDestinatarios, setdadosDestinatarios] = useState(false);
    const [destinatarioEndereco, setdestinatarioEndereco] = useState(false);
    const [remetenteEndereco, setremetenteEndereco] = useState(false);
    const [buscarCarrinho, setBuscarCarrinho] = useState([])
useEffect(() => {
  carrinhoService.getCarrinho((erroCarinho, sucesso) => {
      if(erroCarinho){

          if(erroCarinho.response.data.code === "NOT_FOUND" && erroCarinho.response.data.message ==="Nenhum carrinho ativo encontrado"){
            setBuscarCarrinho()
            return;
          }
          setErro({
            isWrong: true,
            mensagem: 'Falha ao obter dados do carrinho'
          })
      }
      if(sucesso){
        setBuscarCarrinho(sucesso)
        carrinhoService.setCarrinhoInfosParaCompra(sucesso)
        carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)
      }
  })
}, [])

    const history = useHistory();

      useEffect(() => {

        if(carrinhoInformacoes?.conteudo?.itens?.length > 0){
          setShowDeclaracao(true)
        }
        // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      useEffect(() => {
        getCEP(cotacao.cepDestino)
        getCEPOrigem( loja?.ativo ? loja?.endereco.cep : cotacao.cepOrigem)
        geografiaService.obtemEstados((erroEstados, sucesso) => {
          if(erroEstados){
            setErro({
              isWrong: true,
              mensagem: 'Falha ao obter estados, entre em contato com o suporte ou tente novamente mais tarde'
            })
            return;
          }
          if(sucesso){
            setEstados(sucesso);
          }
        })
        // TODO: esse useEffect deve ser usado apenas quando a pagina é carregada --> By Renan
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      function removeAcento (text)
        {
            text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
            text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
            text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
            text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
            text = text.replace(new RegExp('[ÚÙÛŨ]','gi'), 'u');
            text = text.replace(new RegExp('[Ç]','gi'), 'c');
            return text;
        }

      const getCidades = (event, tipo) => {
          let cidade;

          if(event.target){
            const { value } = event.target
            cidade = value
          } else {
            cidade = event
          }
          geografiaService.obtemMunicipios(cidade, (erroCidade, sucesso) => {
            if(erroCidade) {
              setErro({
                isWrong: true,
                mensagem: 'Falha ao obter municipios, entre em contato com o suporte ou tente novamente mais tarde'
              })
            }else{
              if(tipo === "origem"){
                setCidades(sucesso)
              }else if (tipo === "destino"){
                setCidadesDestino(sucesso)
              }

            }

          })

      }

    useEffect(() => {
    if (finalizarPagamento && processando === false) {
      history.push("/carrinho");
    }
  }, [finalizarPagamento, history, processando]);

      const getCEP = (cep) => {
        if(!cep){
          return;
        }
        geografiaService.buscaPorCEP(cep, (erroCep, sucesso) => {
          if(erroCep){
            setErro({
              isWrong: true,
              mensagem: 'CEP não localizado. Insira manualmente informações do endereço.'
            })
            return;
          }
          if(sucesso){
            setCepEndereco({
              cep: sucesso.cep,
              bairro: sucesso.bairro,
              nomeCidade: sucesso.nomeCidade,
              cidade: sucesso.cidade,
              siglaUf: sucesso.siglaUf,
              logradouro: sucesso.logradouro,
              nomeUf: sucesso.nomeUf
            });
            setLogradouro(sucesso.logradouro)
            setBairro(sucesso.bairro)
            setCity(sucesso.cidade);
            setDisabledEndereco({
              enderecoBairro: (sucesso.logradouro && sucesso.bairro !== null) && sucesso.bairro?.trim().length > 0,
              estadoMunicipio: true
            });
          }
            if(!cepEndereco.nomeCidade){
              getCidades(sucesso.siglaUf, "destino");
            }

        });
      }


      const getCEPOrigem = (cep) => {
        if(!cep){
          return;
        }
        const enderecoUsuarioLogado = JSON.parse(localStorage.getItem('usuario-endereco'));

        let cepIgual = false
        let uf = ''
        if(enderecoUsuarioLogado?.cep){
            const cepUsuarioNumeros = enderecoUsuarioLogado.cep.replace(/\D/g, '');
            cepIgual = cep === cepUsuarioNumeros;
            if(cepIgual || (loja?.uuid && loja?.ativo && loja?.endereco?.cep === cep)){
                setCepEnderecoOrigem({
                    cep: cep,
                    bairro: loja?.ativo ? loja?.endereco.bairro :  enderecoUsuarioLogado.bairro,
                    nomeCidade: loja?.ativo ? loja?.endereco.nomeCidade : enderecoUsuarioLogado.nomeCidade,
                    cidade: loja?.ativo ? loja?.endereco.cidade : enderecoUsuarioLogado.cidade,
                    siglaUf: loja?.ativo ? loja?.endereco.estado : enderecoUsuarioLogado.estado,
                    logradouro: loja?.ativo ? loja?.endereco.logradouro : enderecoUsuarioLogado.logradouro,
                    nomeUf: loja?.ativo ? loja?.endereco.nomeUf : enderecoUsuarioLogado.nomeUf,
                    complemento:  loja?.ativo ? loja.endereco.complemento.substring(0,maxCaracteresComplementoEndereco) : enderecoUsuarioLogado?.complemento?.substring(0,maxCaracteresComplementoEndereco),
                    numero: loja?.ativo ? loja?.endereco.numero.substring(0,maxCacteresNumeroEndereco) : enderecoUsuarioLogado?.numero?.substring(0,maxCacteresNumeroEndereco)
                });

                setRequest({
                  ...request,
                  complementoOrigem: loja?.ativo ? loja.endereco.complemento.substring(0,maxCaracteresComplementoEndereco) : enderecoUsuarioLogado?.complemento?.substring(0,maxCaracteresComplementoEndereco),
                  numeroOrigem: loja?.ativo ? loja?.endereco.numero.substring(0,maxCacteresNumeroEndereco) : enderecoUsuarioLogado?.numero?.substring(0,maxCacteresNumeroEndereco)
                })

                setLogradouroOrigem(loja?.ativo ? loja?.endereco.logradouro : enderecoUsuarioLogado.logradouro)
                setBairroOrigem(loja?.ativo ? loja?.endereco.bairro : enderecoUsuarioLogado.bairro)
                setCityOrigem(loja?.ativo ? loja?.endereco.cidade : enderecoUsuarioLogado.cidade)
                uf = loja?.ativo ? loja?.endereco.estado : enderecoUsuarioLogado.estado;

            }

        }
          geografiaService.buscaPorCEP(cep, (erroCep, sucesso) => {
              if(erroCep){
                  setErro({
                      isWrong: true,
                      mensagem: 'CEP não localizado. Insira manualmente informações do endereço.'
                  })
                  if(!cepIgual) return;
              }
              if(sucesso){
                  const dadosFiltrados = Object.entries(sucesso).reduce((acc, [key, value]) => {
                      if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0 )) {
                          acc[key] = value;
                      }
                      return acc;
                  }, {});

                  const enderecoLoja = loja?.endereco;
                  //deve considierar
                  let dadosAtualizados = null;
                  if(loja?.ativo){
                    dadosAtualizados = { ...dadosFiltrados, ...enderecoLoja };
                  }else {
                    dadosAtualizados = cepIgual ? { ...dadosFiltrados, ...enderecoUsuarioLogado } : sucesso;
                  }
                  setCepEnderecoOrigem({
                      cep: dadosAtualizados.cep,
                      bairro: dadosAtualizados.bairro,
                      nomeCidade: dadosAtualizados.nomeCidade,
                      cidade: dadosFiltrados.cidade,
                      siglaUf: dadosFiltrados.siglaUf,
                      logradouro: dadosAtualizados.logradouro,
                      nomeUf: dadosFiltrados.nomeUf
                  });
                  if(!logradouroOrigem){
                      setLogradouroOrigem(dadosAtualizados.logradouro)
                  }
                  if(!bairroOrigem){
                      setBairroOrigem(dadosAtualizados.bairro)
                  }
                  setCityOrigem(dadosAtualizados.cidade)
                  setDisabledEnderecoOrigem({
                      enderecoBairro: (dadosFiltrados.logradouro?.trim()?.length > 0 && dadosFiltrados.bairro?.trim()?.length > 0),
                      estadoMunicipio: true
                  });
                  uf = sucesso.siglaUf;

              }
              if(uf){
                  if(!cepEnderecoOrigem.nomeCidade){
                    getCidades(uf, "origem")
                  }

              }
          });


        let cincoPrimeirosDigitosCep = cep.substring(0,5)
        agenciaService.obterAgenciasDeUmaTransportadora(cotacao.transportadora.uuid, cincoPrimeirosDigitosCep, (erroAgencia, sucesso) => {
            if(erroAgencia){
                setErro({
                  isWrong: true,
                  mensagem: 'Agência não localizada.'
                })
                return;
              }
            if(sucesso){
                setAgencias(sucesso)
            }
        });
      }

    const onChange = (event) => {
        const { name, value } = event.target

        if(event._reactName === 'onFocus' && event.target.name === 'cpfCnpj'){
          event.target.setCustomValidity("Preencha este campo.");
          return
        } else {
          event.target.setCustomValidity("");
        }

        if(event._reactName === 'onFocus' && event.target.name === 'telefone'){
          event.target.setCustomValidity("Preencha este campo.");
          return
        } else {
          event.target.setCustomValidity("");
        }

        let req = request
        req[name] = value
        setRequest({...req})
    }

      const onChangeSemNumero = (event) => {
        setSemNumero(event.target.checked)
        if(event.target.checked){
          numero.current.value = "S/N"
        } else {
          numero.current.value = ""
        }
      }

      const onChangeSemNumeroCepOrigem = (event) => {
        setSemNumeroOrigem(event.target.checked)
        if(event.target.checked){
          numeroOrigem.current.value = "S/N"
        } else {
          numeroOrigem.current.value = ""
        }
      }

      const onChangeEndereco = (event) => {
        const { name, value } = event.target
        if(event._reactName === 'onFocus' && event.target.name === 'cep'){
          event.target.setCustomValidity("Preencha este campo.");
        } else {
          event.target.setCustomValidity("");
        }
        let req = request
        req[name] = value
        setCepEndereco({...req})
        };


        const onChangeEnderecoCepOrigem = (event) => {

          const { name, value } = event.target

          if(event._reactName === 'onFocus' && event.target.name === 'cep'){
            event.target.setCustomValidity("Preencha este campo.");
          } else {
            event.target.setCustomValidity("");
          }

          let req = request
          req[name] = value
          setCepEnderecoOrigem({...req})
          };

          const onChangeEnderecoLogradouro = (event) => {

            setLogradouro(event.target.value)
          };

          const onChangeEnderecoBairro = (event) => {

            setBairro(event.target.value)
          };

          const onChangeEnderecoLogradouroOrigem = (event) => {


            setLogradouroOrigem(event.target.value)
          };

          const onChangeEnderecoBairroOrigem = (event) => {

            setBairroOrigem(event.target.value)
          };

      const onBlur = (event) =>{
        let cep = event.target.value;
        if(!cep.length){
          return
        }
        let formatedcep = cep.replace(/[^0-9]/g, "")
        return getCEP(formatedcep);

      };

      const onBlurCepOrigem = (event) =>{
        let cep = event.target.value;
        if(!cep.length){
          return
        }
        let formatedcep = cep.replace(/[\D]/g, "")
        return getCEPOrigem(formatedcep);

      };
      let  {freteCotacoesCarrinho, freteCotacoes} = useSelector((state) => {
        return {
          ...state
        };
      });
      const voltarListTransportadoras = () => {
        const payload = edit? freteCotacoesCarrinho: freteCotacoes;
        redirect({dest: "cotacoes", payload});
      }

      const setRemetente = () => {
        const enderecoBase = {
          cep: cotacao.cepOrigem,
          logradouro: logradouroOrigem || cepEnderecoOrigem.logradouro,
          bairro: bairroOrigem || cepEnderecoOrigem.bairro,
          cidade: cepEnderecoOrigem.nomeCidade || cityOrigem,
          estado: cepEnderecoOrigem.siglaUf || cityOrigem
        };

        const getNumero = (cep, lojaCep, lojaNumero, requestNumero) => {
          return cep === lojaCep ? lojaNumero : requestNumero || "S/N";
        };

        const getComplemento = (cep, lojaCep, lojaComplemento, requestComplemento) => {
          return removeAcento(cep === lojaCep ? lojaComplemento : requestComplemento);
        };

        if (loja?.uuid && loja?.ativo && !usarDadosUsuario) {
          return {
            nome: loja?.nome,
            sobrenome: "",
            cpfCnpj: loja?.cnpj,
            telefone: usuario.telefone,
            email: usuario.email,
            endereco: {
              ...enderecoBase,
              numero: getNumero(cotacao.cepOrigem, loja?.endereco?.cep, loja?.endereco?.numero, "S/N"),
              complemento: getComplemento(cotacao.cepOrigem, loja?.endereco?.cep, loja?.endereco?.complemento, request.complementoOrigem),
            }
          };
        } else {
          return {
            nome: usuario.name,
            sobrenome: usuario.sobrenome,
            cpfCnpj: usuario.username,
            telefone: usuario.telefone,
            email: usuario.email,
            endereco: {
              ...enderecoBase,
              numero: getNumero(cotacao.cepOrigem, loja?.endereco?.cep, request.numeroOrigem, request.numeroOrigem),
              complemento: getComplemento(cotacao.cepOrigem, loja?.endereco?.cep, request.complementoOrigem, request.complementoOrigem),
            }
          };
        }
      };



      const submitInscricao = (event) => {

        tagueamentoService.eventoMarketingAdicionarAoCarrinho(cotacao);

        event.preventDefault();
        window.scrollTo(0, 0)
        setProcessando(true)

        let callback

        if(event.nativeEvent.submitter.value === "Finalizar pagamento"){
          setfinalizarPagamento(true)
          callback = function finalizarPagamentoFrete(){
            setfinalizarPagamento(true)
          }
        }else{
          setfinalizarPagamento(false)
          callback = modalCarrinho
        }

        let telToBeFormated = request.telefone || carrinhoInformacoes?.destinatario?.telefone
        let formatedTel
        if(telToBeFormated){
          formatedTel = telToBeFormated.replace(/[\D]/g, "")
        }
        const resultTel = validarTelefone(formatedTel);
        if(!resultTel){
          setErro({
            isWrong: true,
            mensagem: "Telefone inválido."
          })
          erroFinalizarPagamento()
          setEnviandoInformacoes(false)
          return
        }

        const emailParaSerValidado = request.email || carrinhoInformacoes?.destinatario?.email
        const resultEmail = validarEmail(emailParaSerValidado);
        if(!resultEmail){
          setErro({
            isWrong: true,
            mensagem: "E-mail inválido."
          })
          erroFinalizarPagamento()
          setEnviandoInformacoes(false)
          return
        }

        const paraSerFormatado = request.cpfCnpj || carrinhoInformacoes?.destinatario?.cpfCnpj
        let formated = paraSerFormatado.replace(/\D/g, "")

        if(!isCnpj){
          const resultCpf = validacaoDo.cpf(formated);
          if(!resultCpf){
            setErro({
              isWrong: true,
              mensagem: "CPF inválido."
            })
            erroFinalizarPagamento()
            setEnviandoInformacoes(false)
            return
          }
        } else {
          const resultCpf = validacaoPj.cnpj(formated);
          if(!resultCpf){
            setErro({
              isWrong: true,
              mensagem: "CNPJ inválido."
            })
            erroFinalizarPagamento()
            setEnviandoInformacoes(false)
            return
          }
        }
        if(cotacao.transportadora?.agenciaObrigatoria && !agenciaSelect){
          setErro({
            isWrong: true,
            mensagem: "Selecione uma agencia."
          })
          erroFinalizarPagamento()
          setEnviandoInformacoes(false)
          return
        }

        if(showDeclaracao){
          const isInvalid = conteudo.find(cont => !cont.descricao || !cont.quantidade || cont.descricao === '' || cont.quantidade === '');
          if(isInvalid){
            setErro({
              isWrong: true,
              mensagem: "Preencha corretamente a declaração de conteúdo."
            })
            erroFinalizarPagamento()
            setEnviandoInformacoes(false)
            return
          }
        }

        if(showNotaFiscal){
          if(numeroNotaFiscal.replace(/\D/g, "").length !== 44){
            const element = document.getElementById('preenchimentoNotaFiscal')
            if(element){
              element.setCustomValidity('Este campo deve conter 44 algarismos e podem ser utilizados espaços entre os grupos de algarismos.');
              element.focus();
              element.reportValidity();
            }
            erroFinalizarPagamento()
            setEnviandoInformacoes(false)
            return
          }
        }

        let agenciaTipoStr = "";
        if(agenciaSelect){
          let tipo = agenciaSelect.split(" ")
          agenciaTipoStr = tipo[tipo.length - 1]
        }

        const remetente = setRemetente()
        const calculadoraInfosComplementaresRequest = {
            carrinhoKey: uuidv4(),
            identificadorCotacao: cotacao.uuid,
            identificadorLoja: "",
            agencia: agenciaSelect,
            agenciaTipo: agenciaTipoStr,
            destinatario: {
                nome: request.nome,
                sobrenome: request.sobrenome,
                cpfCnpj: formated,
                isCnpj,
                telefone: formatedTel,
                email: request.email,
                endereco: {
                  numero: !request.numero || semNumero === true ? "S/N" : request.numero,
                  complemento: removeAcento(request.complemento),
                  cep: cotacao.cepDestino,
                  logradouro: logradouro ? logradouro : cepEndereco.logradouro,
                  bairro: bairro ? bairro : cepEndereco.bairro,
                  cidade: !cepEndereco.nomeCidade ? city : cepEndereco.nomeCidade,
                  estado: !cepEndereco.siglaUf ? city : cepEndereco.siglaUf
                },
            },
            remetente: remetente,
            conteudo: showDeclaracao ? {
                valor: Number(valorComparativo),
                itens: conteudo
            } : null,
            notaFiscal: showNotaFiscal ? {
              valorNotaFiscal: Number(valorComparativo),
              numeroDanfCte: numeroNotaFiscal.replace(/\D/g, ""),
              cfop: cfop.padStart(4, '0')
            } : null,
            descricaoConteudo: {},
            infoCotacao: cotacao
        }

        let finalObj = []
        finalObj.push(calculadoraInfosComplementaresRequest)
        finalObj.forEach((i)=>
        {
          if(i.agencia !== null || i.agencia !== "" ){
            setagenciaOk(true)
          }

          if((i.destinatario.nome !== null || i.destinatario.nome !== "")
          && (i.destinatario.cpfCnpj !== null || i.destinatario.cpfCnpj !== "")
          && (i.destinatario.email !== null || i.destinatario.email !== "") && (i.destinatario.telefone !== null || i.destinatario.telefone !== "") ){
            setdadosDestinatarios(true)
          }

          if((i.destinatario.endereco.logradouro !== null || i.destinatario.endereco.logradouro !== "") && (i.destinatario.endereco.cep !== null || i.destinatario.endereco.cep !== "")
          && (i.destinatario.endereco.cidade !== null || i.destinatario.endereco.cidade !== "") && (i.destinatario.endereco.bairro !== null || i.destinatario.endereco.bairro !== "")
          && (i.destinatario.endereco.estado !== null || i.destinatario.endereco.estado !== "") && (i.destinatario.endereco.numero !== null || i.destinatario.endereco.numero !== "")){
            setdestinatarioEndereco(true)
          }

          if((i.remetente.endereco.logradouro !== null || i.remetente.endereco.logradouro !== "") && (i.remetente.endereco.cep !== null || i.remetente.endereco.cep !== "")
          && (i.remetente.endereco.cidade !== null || i.remetente.endereco.cidade !== "") && (i.remetente.endereco.bairro !== null || i.remetente.endereco.bairro !== "")
          && (i.remetente.endereco.estado !== null || i.remetente.endereco.estado !== "") && (i.remetente.endereco.numero !== null || i.remetente.endereco.numero !== "")){
            setremetenteEndereco(true)
          }

        })

        let finalArrayRequest = []

        if(buscarCarrinho){
          buscarCarrinho?.fretes.forEach((item) => {
          const fretes = {
                identificadorCotacao: item.identificadoCotacao,
                numeroPedidoUsuario: [authService.usuarioCorrente()?.identificadorAssinatura],
                destinatario: item.destinatario,
                remetente: item.remetente,
                conteudo: item.conteudo || null ,
                agenciaEndereco: item.agencia,
                agenciaTipo: item.agenciaTipo || "",
                notaFiscal: item.notaFiscal || null,
          }
          finalArrayRequest.push(fretes)
        })
        }
        const requestNewFrete = {
          identificadorCotacao: cotacao.uuid,
          numeroPedidoUsuario: [authService.usuarioCorrente()?.identificadorAssinatura],
          agenciaEndereco: agenciaSelect,
          agenciaTipo: agenciaTipoStr,
          destinatario: {
            nome: request.nome,
            sobrenome: request.sobrenome,
            cpfCnpj: formated,
            isCnpj,
            telefone: formatedTel,
            email: request.email,
            endereco: {
              numero: !request.numero || semNumero === true ? "S/N" : request.numero,
              complemento: removeAcento(request.complemento),
              cep: cotacao.cepDestino,
              logradouro: logradouro ? logradouro : cepEndereco.logradouro,
              bairro: bairro ? bairro : cepEndereco.bairro,
              cidade: !cepEndereco.nomeCidade ? city : cepEndereco.nomeCidade,
              estado: !cepEndereco.siglaUf ? city : cepEndereco.siglaUf
            },
          },
          remetente: remetente,
          conteudo: showDeclaracao ? {
            valor: Number(valorComparativo),
            itens: conteudo
          } : null,
          notaFiscal: showNotaFiscal ? {
            numeroDanfCte: numeroNotaFiscal.replace(/[^\d]/g, ""),
            valorNotaFiscal: Number(valorComparativo),
            cfop: cfop.padStart(4, '0')
          } : null
        }

        finalArrayRequest.push(requestNewFrete)
        const finalobjCarrinho = {fretes: finalArrayRequest}

        setProcessando(true)
        carrinhoService.postCriarEditarCarrinho(finalobjCarrinho, (erroCarrinho, sucesso)=>{
          if(erroCarrinho){
            setErro({
              isWrong: true,
              mensagem: erroCarrinho?.response?.data?.message || 'Erro ao adicionar item ao carrinho.'
            })
            erroFinalizarPagamento()
            return
        }
        if(sucesso){
           carrinhoService.setNumeroItensInfosParaCompra(sucesso.fretes.length)
           setProcessando(false)
            if(edit){
                callback();
                return;
            }
            callback();
        }})

      }


      const callbackModal = () =>{
        if(erro.elementCallback !== null && typeof erro.elementCallback === 'function'){
          erro.elementCallback()
        }

        if(erro.isWrong === true){
          setErro({isWrong: false})
        }
      }

      const callbackModalCalculadora = () =>{
        if(adicionado.isAdd === true){
          setAdicionado({isAdd: false})
        }
      }

      const modalCarrinho = () => {
          setAdicionado({
              isAdd: true,
              mensagem: "Pronto! Seu frete foi adicionado ao carrinho.",
          })

      }
  const erroFinalizarPagamento = () => {
    setProcessando(false)
    setfinalizarPagamento(false)
      }

      // const showDeclaracaoConteudo = (e) => {
      //     setShowDeclaracao(e.target.checked);
      // }

      const onBlurTelefone = (e) => {
        const {value} = e.target
        if(value[value.length - 1] === "_"){
          setTelefoneMask("(99)9999-9999");
        }else{
          setTelefoneMask("(99)99999-9999")
        }
      }
      const onFocusTelefone = () =>{
         setTelefoneMask("(99)99999-9999");
      }



      const preencheuDeclaracaoConteudo = () => {
        return !(preencheConteudo === false && !carrinhoInformacoes?.conteudo?.itens) && showDeclaracao
      }

      const preencheuNotaFiscal = () => {
        return numeroNotaFiscal !== null && numeroNotaFiscal.length > 0 && showNotaFiscal
      }

  return (
      <>
        {
        enviandoInformacoes === true || processando === true?
        (
        <FlexSpinner />
        ) : null
        }
        {
        erro.isWrong === true ?
        (
            <Modal callbackModal={callbackModal} visivel={true} titulo={erro.titulo} conteudo={erro.mensagem} />
        ) : null
        }

      {
        adicionado.isAdd === true ?
        (
            <CalculadoraModal callbackModalCalculadora={callbackModalCalculadora} visivel={true} titulo={adicionado.titulo}
                              conteudo={adicionado.mensagem} preencheConteudo={preencheConteudo} agenciaOk={agenciaOk} dadosDestinatarios={dadosDestinatarios}
                              destinatarioEndereco={destinatarioEndereco} remetenteEndereco={remetenteEndereco} />

        ) : null
        }

        {
                sucesso.isWrong === false  ?
                (
                <Modal callbackModal={callbackModalSucesso} visivel={true} titulo={sucesso.titulo} conteudo={sucesso.mensagem} />
                ) : null
            }

    <FormStyle onSubmit={submitInscricao}>

      <CalculadoraComplementarForm>
        <div>
            <h2 className="form-title page-title">Dados do destinatário</h2>
            <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
                <Input type="text" name="nome" label="Nome*" defaultValue={request.nome} required={true} onChange={onChange}/>
                <Input type="text" name="sobrenome" label="Sobrenome*" defaultValue={request.sobrenome} required={true} onChange={onChange}/>
            </div>

            <div className="section-half" style={{marginTop: '20px', marginBottom: '20px', display:'flex', alignItems:'center'}}>
            <label style={{display:'flex', alignItems:'center'}}>
              <span style={{marginRight: '5px'}}>CPF</span>

              <Switch onChange={e => {setIsCnpj(!isCnpj)}} checked={isCnpj} offColor="#08f" onColor="#08f"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "orange",
                      paddingRight: 2
                    }}
                  >
                  </div>
                }

                checkedIcon={
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    color: "orange",
                    paddingRight: 2
                  }}>
                </div>
                }
              />
              <span style={{marginLeft: '5px'}}>CNPJ</span>


            </label>
            </div>



            <Input type="text" mascara={!isCnpj? "999.999.999-99": "99.999.999/9999-99"} name="cpfCnpj" label={!isCnpj? "CPF*": "CNPJ*"} defaultValue={request.cpfCnpj} maxLength="18" required={true} onChange={onChange}/>

            <hr />
            <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
                <Input type="email" name="email" label="E-mail*" defaultValue={request.email} required={true} onChange={onChange}/>
                <Input type="tel" mascara={telefoneMask} name="telefone" defaultValue={request.telefone} label="Telefone*" required={true} onChange={onChange} onFocus={onFocusTelefone} onBlur={onBlurTelefone}/>
            </div>
        </div>

        <div>

          <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
              <Input type="text" mascara="99999-999" name="cep" label="CEP*" defaultValue={cotacao.cepDestino} required={true} disabled={true}  onBlur={onBlur}/>
              <Input type="text" name="logradouro" label="Endereço*" value={logradouro} disabled={disabledEndereco.enderecoBairro === true} required={true} onChange={onChangeEnderecoLogradouro}/>
          </div>

          <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
              <Input type="text" name="bairro" label="Bairro*" value={bairro} disabled={disabledEndereco.enderecoBairro === true} required={true} onChange={onChangeEnderecoBairro}/>
              <div>
                <div className="field">
                  <label className="label">Número*</label>
                  <div className="control">
                      <input className="input" type="text" maxlength = {maxCacteresNumeroEndereco} name="numero" ref={numero} disabled={semNumero} defaultValue={request.numero} required={semNumero} onChange={onChange}/>
                  </div>
                </div>
                <small style={{textAlign: 'left'}}>
                  <div className="field">
                    <div className="control">
                    <label style={{fontWeight: '700', color: '#282828'}}>
                      <input type="checkbox" name="numero" onChange={onChangeSemNumero}/> Sem número
                    </label>
                    </div>
                  </div>
                </small>
              </div>
          </div>

          <Input type="text" name="complemento" label="Complemento" maxLength={maxCaracteresComplementoEndereco} onChange={onChange} defaultValue={request.complemento}/>

          <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
            <div style={{marginBottom: '1.5em'}}>
              <label className="label">Cidade*</label>
              <div className="select" style={{width: "100%"}}>
              <select
                  value={cepEndereco.cidade || undefined}
                  name="cidade"
                  style={{width: "100%"}}
                  disabled={disabledEndereco.estadoMunicipio}
                  onChange={onChangeEndereco}
                >
                  {disabledEndereco.estadoMunicipio ? (
                    <option key={cepEndereco.cidade || 'default'} value={cepEndereco.cidade}>
                      {cepEndereco.nomeCidade}
                    </option>
                  ) : (
                    cidadesDestino.map((cidade, index) => (
                      <option key={cidade.uuid} value={cidade.uuid}>
                        {cidade.nome}
                      </option>
                    ))
                  )}
              </select>
              </div>
            </div>
            <div style={{marginBottom: '1.5em'}}>
                <label className="label">Estado*</label>
                <div className="select" style={{width: "100%"}}>
                  <select value={cepEndereco.siglaUf || undefined} name="estado" style={{width: "100%"}} disabled={disabledEndereco.estadoMunicipio} onChange={getCidades}>
                      {
                        estados.map((estado, index) => {
                          return (
                            <option key={Math.random() + index}
                                    value={estado.nome}
                            >

                              {estado.nomeExtenso} - {estado.nome}

                            </option>
                          )
                        })
                      }
                  </select>
                </div>
              </div>
          </div>
        </div>
      </CalculadoraComplementarForm>

{
  cotacao.transportadora?.agenciaObrigatoria === true ?
  (
    <CalculadoraComplementarForm>
    <div>
        <h2 className="form-title page-title">Escolha onde despachar a encomenda*</h2>
        <div style={{marginBottom: '1.5em'}}>
            <label className="label">Selecione um endereço</label>
                    <div className="select" style={{width: "100%"}}>
                      <select value={agenciaSelect} name="agenciaSelect" style={{width: "100%"}} required={true} onChange={event => {
                        setAgenciaSelect(event.target.value)
                      }}>
                        <option value=""></option>
                        {
                          agencias.map((agencia, index) => {
                            return (
                              <option key={Math.random() + index}
                                      value={agencia.uuid}
                                >

                                {agencia.endereco.logradouro}, {agencia.endereco.bairro}, {agencia.endereco.cidade} - {agencia.uf} ({agencia.nickname}) {agencia.tipo}

                              </option>
                            )
                          })
                        }
                      </select>
                  </div>
            </div>
    </div>
    </CalculadoraComplementarForm>
  ): ""
}

    <CalculadoraComplementarForm>
    <div>
        <h2 className="form-title page-title">Dados do remetente</h2>
        <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
            <Input type="text" mascara="99999-999" name="cep" label="CEP*" defaultValue={cotacao.cepOrigem} required={true} disabled={true} onBlur={onBlurCepOrigem}/>
            <Input type="text" name="logradouro" label="Endereço*" value={logradouroOrigem} disabled={disabledEnderecoOrigem.enderecoBairro === true} required={true} onChange={onChangeEnderecoLogradouroOrigem}/>
        </div>

            <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>

                <Input type="text" name="bairro" label="Bairro*" value={bairroOrigem} disabled={disabledEnderecoOrigem.enderecoBairro === true} required={true} onChange={onChangeEnderecoBairroOrigem}/>
                <div>
                  <div className="field">
                  <label className="label">Número*</label>
                  <div className="control">
                      <input className="input" maxlength = {maxCacteresNumeroEndereco} type="text" name="numeroOrigem" ref={numeroOrigem} value={request.numeroOrigem} disabled={semNumeroOrigem === true} required={semNumeroOrigem !== true} onChange={onChange}/>
                  </div>
                </div>
                <small style={{textAlign: 'left'}}>
                  <div className="field">
                    <div className="control">
                    <label style={{fontWeight: '700', color: '#282828'}}>
                      <input type="checkbox" name="numero" onChange={onChangeSemNumeroCepOrigem}/> Sem número
                    </label>
                    </div>
                  </div>
                </small>
            </div>
          </div>
          <Input type="text" name="complementoOrigem" maxLength={maxCaracteresComplementoEndereco}  label="Complemento" onChange={onChange} value={request.complementoOrigem || ''}/>
          <div className="section-half" style={{marginTop: '20px', marginBottom: '20px'}}>
            <div style={{marginBottom: '1.5em'}}>
              <label className="label">Cidade*</label>
              <div className="select" style={{width: "100%"}}>
                <select value={cepEnderecoOrigem.cidade ? cepEnderecoOrigem.cidade : undefined} name="cidade" style={{width: "100%"}} disabled={disabledEnderecoOrigem.estadoMunicipio === true} onChange={onChangeEnderecoCepOrigem}>
                  {
                    cidades.map((cidade, index) => {
                      return (
                        <option key={Math.random() + index}
                                value={cidade.uuid}
                          >

                          {cidade.nome}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div style={{marginBottom: '1.5em'}}>
                <label className="label">Estado*</label>
                <div className="select" style={{width: "100%"}}>
                  <select value={cepEnderecoOrigem.siglaUf ? cepEnderecoOrigem.siglaUf : undefined} name="estado" style={{width: "100%"}} disabled={disabledEnderecoOrigem.estadoMunicipio === true ? true : false} onChange={getCidades}>
                      {
                        estados.map((estado, index) => {
                          return (
                            <option key={Math.random() + index}
                                    value={estado.nome}
                            >

                              {estado.nomeExtenso} - {estado.nome}

                            </option>
                          )
                        })
                      }
                  </select>
              </div>
            </div>
          </div>
    </div>
    </CalculadoraComplementarForm>

    <CalculadoraComplementarForm>
      <div>
        <h2 className="form-title page-title">Conte&uacute;do do frete</h2>
      </div>
      <p className="valores-declaracao">
          Valor da encomenda: <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorComparativo)}</span>
      </p>
      <p className="declaracao-pergunta">Como deseja declarar ?</p>

      <div className='declaracao'>
        <div>
          <input className="radio" type="radio" name="tipoDeclaracao" onChange={handleNotaFiscalChange}/>
          Apresentação da Nota Fiscal
        </div>

        { showNotaFiscal && (
          <div className="nota-fiscal-container">
            <div className="section-half nota-fiscal">
              <Input
                id="preenchimentoNotaFiscal"
                label="Chave da NF (44 algarismos)"
                value={numeroNotaFiscal}
                style={{ marginRight: '0px'}}
                type="text"
                onChange={setNotaFiscal}
                maxLength={maxCaracteresNotaFiscal}
                required={true}
                />
              <p>{numeroNotaFiscal?.toString().replace(/[^\d]/g, "").length || 0}/44</p>
            </div>
              <div className="section-half nota-fiscal">
                  <Input
                      id="cfop"
                      label="CFOP"
                      value={cfop}
                      style={{ marginRight: '0px', maxWidth:'100px'}}
                      type="text"
                      onChange={setCfop}
                      maxLength={maxCfop}
                      required={true}
                  />
                  <p>{cfop?.toString().replace(/\D/g, "").length || 0}/4</p>
              </div>
          </div>
        )}

        {
            (valorComparativo <= VALOR_MAXIMO_DECLARACAO_CONTEUDO) ? (
          <div>
            <input className="radio" type="radio" name="tipoDeclaracao" onChange={handleDeclaracaoChange}/>
            Formulário de Declaração de Conte&uacute;do
          </div>
          ) : (
          <div className='declaracao-disable'>
            <input className='input-disable' disabled={true} type="radio" name="tipoDeclaracao" checked={false} />
            Formulário de Declaração de Conte&uacute;do
            <p >
              Dispon&iacute;vel somente para valores de encomenda de at&eacute; {moneyMask(VALOR_MAXIMO_DECLARACAO_CONTEUDO.toString())}
            </p>
          </div>
        )}

        { showDeclaracao &&   (
          <div className='declaracao-conteudo'>
            <label className='label'>Descrição do item</label>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>

                <textarea
                  name={'descricao'}
                  className='input'
                  placeholder='Descrever detalhadamente os itens, quantidades e valores'
                  onChange={event => {
                    let conteudoAux = [...conteudo];
                    const { value } = event.target;

                    // colocando um limite de caracteres
                    if(value.length > maxCaracteresDeclaracao) {
                      event.target.value = value.substring(0, maxCaracteresDeclaracao)
                      return
                    }

                    conteudoAux[0].descricao = value;

                  if(!value || value === ''){
                      setPreencheConteudo(false)
                    }else{
                      setPreencheConteudo(true)
                    }
                    setConteudo(conteudoAux)
                  }}>
                    {conteudo[0]?.descricao}
                </textarea>
                <p>{conteudo[0].descricao.toString().length || 0}/255</p>
            </div>
          </div>
        )}
      </div>


    </CalculadoraComplementarForm>

    {
      !preencheuDeclaracaoConteudo() && showDeclaracao ?
      (
        <div className="notification is-danger" style={{marginTop: '20px', marginBottom: '20px'}}>
          <p>
          Por favor, preencha a descrição da declaração de conteúdo.
          </p>
        </div>
      ) : null
    }

    <BtnSection>
      <div  className="alterar-transportadora" onClick={voltarListTransportadoras} >
        <ion-icon name="arrow-back"></ion-icon>
        <input className="button alterar-transportadora" value="Alterar transportadora"/>
      </div>
      <input type="submit" value="Adicionar ao Carrinho" className="button add-carrinho"
        disabled={!preencheuDeclaracaoConteudo() && !preencheuNotaFiscal()} />

        <input type="submit" className="button finalizar-pagamento"
        value="Finalizar pagamento"
         disabled={!preencheuDeclaracaoConteudo() && !preencheuNotaFiscal()}/>
    </BtnSection>
        </FormStyle>

</>

  );
}

export default CalculadoraLogadoInfosComplementares;
